footer {
  background-color: #1a1a1a;
  color: #ffff;
  position: relative;
}

.footer-bg {
  background-image: url(../images/footer-overlay.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.04;
  filter: brightness(0%) contrast(0%) saturate(0%) blur(0px) hue-rotate(0deg);
  height: 100vh;
}

.footer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  padding: 5rem 5rem 0rem;
  transition: all 0.3s ease;
}

.footer-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-container h3 {
  color: #f26a21;
}

.footer-container:nth-child(1) img {
  width: 12rem;
}

.footer-container:nth-child(1) p {
  margin: 0;
}

.footer-container:nth-child(2) ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.information-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.information-container .logo-container {
  padding: 10px;
  background-color: #f26a21;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.information-container i {
  font-size: 20px;
  height: 20px;
  width: 20px;
  text-align: center;
}

.footer-second-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 2rem;
  padding: 1rem 5rem;
  background: linear-gradient(90deg, #f26a21, #ed2123);
}

@media screen and (max-width: 768px) {
  #footer {
    height: auto;
  }

  .footer-bg {
    min-height: 210vh;
  }

  .footer-wrapper {
    padding: 3rem;
    flex-wrap: nowrap;
    max-height: 5000px;
    flex-direction: column;
    gap: 2rem;
  }

  .footer-container {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .footer-second-wrapper {
    justify-content: center;
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 375px) {
  .footer-wrapper {
    padding: 2rem 1rem;
  }

  .footer-second-wrapper {
    font-size: 0.8rem;
    padding: 1rem 2rem;
    gap: 1rem;
  }

  .footer-bg {
    min-height: 195vh;
  }
}
