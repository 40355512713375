.viber-wrapper {
  height: 85vh;
  display: flex;
  justify-content: center;
  gap: 5rem;
}

.viber-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.viber-container .img-container {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  border-radius: 20px;
  padding: 1rem;
  margin: 20px;
}

.viber-container i {
  font-size: 0.9rem;
  color: #7b7b7b;
  text-align: center;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .viber-wrapper {
    gap: 1rem;
    height: auto;
    flex-direction: column;
  }

  .viber-wrapper h1 {
    line-height: 35px;
  }

  .viber-wrapper p {
    line-height: 25px;
  }

  .viber-wrapper i {
    line-height: 20px;
  }

  .viber-container p {
    margin: 0.5rem 0;
  }

  .viber-container .img-container {
    margin: 1rem;
  }
}
