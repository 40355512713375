* {
  box-sizing: border-box;
}

html,
body {
  background-color: #ffff;
  color: #161617;
  font-family: "PoppinsReg", sans-serif;
  scroll-behavior: smooth;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PoppinsBold", sans-serif;
  margin: 0;
  letter-spacing: 0.5px;
}

h1 {
  font-size: clamp(1.8rem, 5vw, 2.2rem);
}

h2 {
  font-size: clamp(1.6rem, 5vw, 2rem);
}

h3 {
  font-size: clamp(1.4rem, 5vw, 1.8rem);
}

h4 {
  font-size: clamp(1.2rem, 5vw, 1.5rem);
}

h5 {
  font-size: clamp(1rem, 5vw, 1.2rem);
}

section {
  width: 100%;
  height: 100vh;
  margin: 0;
  position: relative;
}

section:nth-of-type(even) {
  background-color: #f2f2f2;
}

section:last-child {
  height: auto;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s ease;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 30px;
}

.text-center {
  text-align: center;
}

.main {
  margin-top: 107px;
}

.section-wrapper {
  padding: 0rem 5rem 0rem;
}

.line-height {
  line-height: clamp(40px, 5vw, 4rem);
}

/* "other page wrapper" eg. privacy policy and terms and conditions */
.other-page-wrapper {
  padding: 8rem 5rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.other-page-wrapper ol li {
  padding-bottom: 20px;
}

.other-page-wrapper ul {
  list-style: disc;
  padding-inline-start: 40px;
}

.other-page-wrapper ul li {
  padding-bottom: 0px;
}

.upperCase {
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .section-wrapper {
    padding: 0 2rem 0;
  }
}

@media screen and (max-width: 375px) {
  .section-wrapper {
    padding: 0 1rem 0;
  }

  ul {
    line-height: 25px;
  }
}
