.faqs-wrapper {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

@media screen and (max-width: 480px) {
  .faqs-wrapper {
    padding: 3rem 2rem;
  }
}

@media screen and (max-width: 375px) {
  .faqs-wrapper {
    padding: 3rem 1rem;
  }

  .faqs-wrapper h4,
  .faqs-wrapper h1 {
    padding: 0 1rem;
  }
}
