#plans {
  height: auto;
}

.plans-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.plans-wrapper h1 {
  margin: 0rem;
  text-align: center;
  padding: 1.1rem;
  font-family: "PoppinsBold", sans-serif;
}

.plans-wrapper h2 {
  font-size: clamp(1.6rem, 4vw, 2rem);
  margin: 1rem;
  text-align: center;
  line-height: 30px;
}

.plans-container {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 100%;
  height: 400px;
  justify-content: center;
}

.plans {
  min-width: 30%;
  box-shadow: 0px 0px 23px 0px rgba(87, 45, 3, 0.11);
}

.top-gradient {
  width: 100%;
  background: linear-gradient(180deg, #f26a21, #ed2123);
  height: 20px;
}

.plan-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 380px;
  padding: 1rem 2rem 2rem;
}

.plan-details i {
  margin-right: 5px;
}

.plan-speed {
  display: flex;
  align-items: center;
  height: 50px;
}

.plan-speed p {
  margin: 0;
  height: 50px;
}

.plan-speed p:nth-child(1) {
  display: flex;
  align-items: flex-start;
  font-size: 1.1rem;
  padding-top: 5px;
  color: #f26a21;
}

.plan-speed p:nth-child(2) {
  color: #f26a21;
  font-size: 2rem;
  font-weight: 600;
}

.plan-speed p:nth-child(3) {
  font-family: "PoppinsMed", sans-serif;
  display: flex;
  height: 50px;
  align-items: flex-end;
  padding-bottom: 8px;
}

@media screen and (max-width: 1024px) {
  .plans-container {
    flex-flow: row nowrap;
    justify-content: flex-start;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 1rem;
    height: 450px;
  }

  .plans {
    min-width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .plans {
    min-width: 60%;
  }
}

@media screen and (max-width: 480px) {
  .plans-container {
    gap: 2rem;
  }

  .plans {
    min-width: 80%;
  }
}

@media screen and (max-width: 375px) {
  .plans-wrapper {
    padding: 0 1rem 0;
  }

  .plans-container {
    gap: 1rem;
    padding: 0.5rem;
  }

  .plans {
    min-width: 90%;
    box-shadow: 0px 0px 5px 0px rgba(87, 45, 3, 0.11);
  }

  .plan-details {
    padding: 1rem;
  }

  .plans-wrapper h2 {
    margin: 0;
    line-height: unset;
  }

  .plan-speed p:nth-child(2) {
    font-size: 1.8rem;
  }

  .plan-speed p:nth-child(3) {
    padding-bottom: 15px;
    font-size: 0.8rem;
  }
}
