nav {
  color: #7e7a7a;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 3rem;
  width: 100%;
  position: fixed;
  background-color: #ffff;
  align-items: center;
  z-index: 999;
  top: 0;
}

nav .logo,
.redirect-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
}

nav img {
  width: 10rem;
}

nav .menu-list {
  flex: 2;
}

nav .menu-list ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 12px;
}

nav .menu-list ul li {
  position: relative;
  margin: 0 10px;
}

nav .menu-list ul li::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  background: linear-gradient(90deg, #ff9771 1.38%, #ff1000 103.02%);
  transition: width 0.3s ease;
}

nav .menu-list ul li:hover::before {
  width: 100%;
}

nav .menu-list ul a.active,
nav .menu-list ul a:hover {
  color: #f26a21;
  transition: all 0.3s ease;
  position: relative;
}

nav .redirect-wrapper {
  gap: 1rem;
}

nav .redirect-wrapper .btn {
  font-size: 0.9rem;
  min-width: 120px;
}

nav .redirect-wrapper img {
  width: 17px;
}

.hamburger {
  display: none;
}

/* mobile view */

@media screen and (max-width: 768px) {
  .redirect-wrapper {
    display: none;
  }

  nav .logo {
    justify-content: flex-start;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger span {
    background-color: #161617;
    width: 25px;
    display: block;
    margin: 5px;
    border: 2px solid;
    border-radius: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  /* adding active class to the bars  */
  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }

  .hamburger.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  nav .menu-list {
    position: absolute;
    top: 84.98px;
    right: 0;
    width: 0;
    height: calc(100vh - 107px);
    transition: 0.7s ease-in-out;
    background-color: #ffff;
  }

  nav .menu-list.active {
    width: 100%;
    height: 100vh;
  }

  nav .menu-list ul {
    display: flex;
    flex-direction: column;
  }

  nav .menu-list ul li {
    margin: 16px 0;
  }
}

@media screen and (max-width: 375px) {
  nav {
    padding: 1.3rem 2rem;
  }

  nav img {
    width: 8rem;
  }

  .hamburger span {
    width: 20px;
    margin: 3px;
  }
  .hamburger.active span:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
}
