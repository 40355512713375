.whyWoofy-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.flex-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.flex-group .reasons {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.flex-item {
  flex: 1;
  flex-basis: 50%;
  max-width: calc(50% - 35px);
}

.reasons .reason-icon {
  background: #f26a21;
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.reasons i {
  color: #ffff;
  font-size: 2.2rem;
}

.reasons div:nth-child(2) {
  text-align: left;
}

.reasons:nth-of-type(even) .reason-icon {
  background-color: #ed2124;
}

.reasons h5 {
  color: #f26a21;
}

.reasons:nth-of-type(even) h5 {
  color: #ed2124;
}

.reasons p {
  font-size: 0.9rem;
  letter-spacing: 0.4px;
  margin: 0;
  margin-top: 5px;
}

.reasons:nth-of-type(even) div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media screen and (max-width: 768px) {
  #why {
    height: auto;
  }

  .whyWoofy-wrapper {
    padding: 4rem 2rem;
    gap: 2rem;
  }

  .flex-item {
    flex-basis: 100%;
    max-width: 100%;
  }

  .reasons .reason-icon,
  .reasons i {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .flex-group {
    gap: 1rem;
  }

  .reasons .reason-icon,
  .reasons i {
    width: 4rem;
    height: 4rem;
    font-size: 1.8rem;
  }

  .reasons p {
    margin: 10px 0px;
  }
}

@media screen and (max-width: 375px) {
  .reasons .reason-icon,
  .reasons i {
    width: 3.2rem;
    height: 3.2rem;
    font-size: 1.5rem;
  }

  .flex-group .reasons {
    gap: 1rem;
  }

  .whyWoofy-wrapper {
    padding: 4rem 1rem;
  }
}
